<template>
  <v-app>
    <nav>
      <div v-if="showTopButtons" class="iconTheme" @click="exit">
        <v-icon>mdi-exit-to-app</v-icon>
      </div>
      <ThemeToggle />
      <div v-if="showTopButtons" class="iconTheme" @click="calendar">
        <v-icon>mdi-calendar</v-icon>
      </div>
    </nav>
    <router-view />
    <ShowDialog ref="dialogRef" />
  </v-app>
</template>

<script>
import ThemeToggle from "./components/ThemeToggle.vue";
import { useTheme } from "vuetify";
import { onMounted, ref, watch } from "vue";
import { useAppStore } from "@/store/app";
import { BASE_URL } from "./components/constants.js";
import { useRouter } from "vue-router";
export default {
  components: {
    ThemeToggle,
  },
  setup() {
    const theme = useTheme();
    const router = useRouter();
    const store = useAppStore();

    const dialogRef = ref(null);
    const showTopButtons = ref(null);

    let inactivityTimer;

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        store.auth = 0;
        store.user = null;
        store.typeUser = null;
        store.clients = [];
        canShowTopButtons();
        localStorage.removeItem("docStoreData");

        router.push({ name: "login" });
      }, 60 * 60 * 1000);
    };

    const handleActivity = () => {
      resetInactivityTimer();
    };

    window.addEventListener("click", handleActivity);
    window.addEventListener("keydown", handleActivity);

    const checkJWT = async () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.auth,
      };

      try {
        const response = await fetch(`${BASE_URL}/api/auth/checkJWT`, {
          method: "POST",
          headers: headers,
        });

        if (response.status === 200) {
          if (store.typeUser == "admin") {
            store.getClients();
          }
        } else {
          store.auth = "";
          router.push({ name: "login" });
        }
        canShowTopButtons();
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const exit = () => {
      store.auth = 0;
      store.user = null;
      store.typeUser = null;
      store.clients = [];
      canShowTopButtons();

      localStorage.removeItem("docStoreData");

      router.push({ name: "login" });
    };

    const calendar = () => {
      if (store.typeUser == "admin") {
        dialogRef.value.setNew("Calendario", "", "calendar");
      } else {
        dialogRef.value.setNew("Citas", "", "appointment");
      }
    };

    const canShowTopButtons = () => {
      if (store.auth && store.auth != "") {
        showTopButtons.value = 1;
      } else {
        showTopButtons.value = 0;
      }
    };

    watch(
      () => store.auth,
      (currentValue, oldValue) => {
        canShowTopButtons();
      }
    );

    onMounted(() => {
      if (store.auth && store.auth !== "") {
        checkJWT();
      } else {
        store.auth = "";
        router.push({ name: "login" });
      }
      canShowTopButtons();
    });

    return {
      exit,
      calendar,
      theme,
      dialogRef,
      showTopButtons,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  text-decoration: none;
}

.iconTheme {
  float: right;
}

/* Estilos para los enlaces */
.nav-link {
  color: inherit; /* Usar el color por defecto de texto */
  text-decoration: none; /* Sin subrayado */
  margin: 0 5px; /* Espaciado entre enlaces */
  transition: color 0.3s; /* Transición suave del color */
}

/* Estilo para enlaces activos */
.active-link {
  color: rgb(
    var(--v-theme-primary)
  ); /* Cambiar el color para enlaces activos */
  font-weight: bold; /* Resaltar enlaces activos */
}

/* Estilo para identificar la página actual */
.v-app.nav-on-home-page {
  background-color: #f0f0f0; /* Cambiar el fondo para indicar la página actual */
}

.insideCard {
  padding: 20px;
}

.v-icon {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.botonD {
  margin-right: 2%;
  margin-top: 2%;
}
</style>
